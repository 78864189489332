const getContributionSvg = ({
    icon = 'snow',
    color = '#007E9C',
    iconColor = '#FFF',
    withoutMarker = false,
} = {}) => {
    const snow = `
        <path fill="${iconColor}" d="M21.3,7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3C22.9,7.2,23,7.5,23,7.7v3l2.4-2
            c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.7,0.4C27,9,27.1,9.2,27.1,9.5c0,0.3-0.2,0.6-0.4,0.7L23,13.4v7.1l5.9-3.5l0.8-4.9
            c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3c0.3,0,0.6,0.1,0.8,0.4c0.2,0.2,0.3,0.6,0.2,0.9l-0.5,3.3l2.7-1.6
            C34,14,34.1,14,34.3,14c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.6,0.2,0.9c-0.1,0.3-0.2,0.6-0.5,0.7l-2.6,1.5l2.8,1.1
            c0.2,0.1,0.5,0.3,0.6,0.5c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.3-0.3,0.5-0.5,0.6c-0.2,0.1-0.5,0.1-0.8,0L30,18.8L24,22.3l5.9,3.5l4.4-1.7
            c0.1,0,0.2-0.1,0.3-0.1c0.3,0,0.7,0.1,0.9,0.4c0.2,0.3,0.3,0.6,0.2,0.9c-0.1,0.3-0.3,0.6-0.6,0.7l-2.8,1.1l2.6,1.5
            c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.3,0.1,0.6-0.1,0.8c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0-0.8-0.1l-2.7-1.6l0.5,3.3
            c0.1,0.3,0,0.6-0.1,0.8c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0-0.5,0-0.8-0.2c-0.2-0.2-0.4-0.4-0.4-0.7l-0.8-4.9L23,24v7.1l-2-1.3V24
            L16,27l-2-1.2l5.9-3.5l-5.9-3.5l-4.4,1.7c-0.2,0.1-0.5,0.1-0.8,0c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.5,0-0.8
            c0.1-0.3,0.3-0.4,0.6-0.5l2.8-1.1l-2.6-1.5c-0.3-0.1-0.4-0.4-0.5-0.7c-0.1-0.3,0-0.6,0.2-0.8C9,14.1,9.3,14,9.6,14
            c0.2,0,0.4,0,0.6,0.1l2.7,1.6l-0.5-3.3c-0.1-0.3,0-0.6,0.2-0.8c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.5,0.1,0.7,0.2
            c0.2,0.2,0.3,0.4,0.4,0.7l0.8,4.9l5.9,3.5v-7.1l-3.6-3.1C17.2,10.1,17,9.8,17,9.6c0-0.3,0.1-0.5,0.2-0.8c0.2-0.2,0.4-0.3,0.7-0.4
            c0.3,0,0.5,0.1,0.7,0.2l2.4,2v-3C21,7.5,21.1,7.2,21.3,7z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" fill="${iconColor}" d="M7.5,25.1c0.4-0.6,1.2-0.9,1.8-0.5l17.8,10.2
            c0.6,0.4,0.9,1.2,0.5,1.8c-0.4,0.6-1.2,0.9-1.8,0.5L8,26.9C7.3,26.6,7.1,25.7,7.5,25.1z"/>
    `;

    const picture = `
        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M13.96,18.4c0-1.1,0.9-2,2-2H33c1.1,0,2,0.9,2,2v13.94
	        c0,1.1-0.9,2-2,2H15.96c-1.1,0-2-0.9-2-2V18.4z M17.06,24.45l5.26-3.71l9.59,10.52H17.06V24.45z M29.74,23.83
	        c1.2,0,2.17-0.97,2.17-2.17s-0.97-2.17-2.17-2.17c-1.2,0-2.17,0.97-2.17,2.17S28.55,23.83,29.74,23.83z"/>
        <path fill="#FFFFFF" d="M5.95,11.99c-0.66,0.16-1.08,0.83-0.92,1.49l3.59,15.05c0.16,0.66,0.83,1.08,1.49,0.92l0.76-0.18V17.64
	        c0-2.39,1.94-4.33,4.33-4.33h11.41l-1.12-4.7c-0.16-0.66-0.83-1.08-1.49-0.92L5.95,11.99z"/>
    `;

    const warning = `
        <path fill="${iconColor}" d="M23.9,8.3l-0.1-0.1c-0.5-0.5-1.1-0.7-1.8-0.7s-1.3,0.3-1.8,0.7l-0.1,0.1L7.2,29.1c-0.5,0.6-0.7,1.4-0.4,2
            c0.3,0.7,1,1.2,1.9,1.2h26.5c0.9,0,1.6-0.4,1.9-1.2c0.3-0.7,0.1-1.4-0.4-2L23.9,8.3z M10.8,29.2l11.2-18l11.2,18L10.8,29.2
            L10.8,29.2z"/>
        <path fill="${iconColor}" d="M20.4,16.2V23c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6v-6.8c0-0.9-0.7-1.6-1.6-1.6
            C21.1,14.6,20.4,15.3,20.4,16.2z"/>
        <circle fill="${iconColor}" cx="21.9" cy="27" r="1.6"/>
    `;

    const heart = `
        <path fill="${iconColor}" stroke="${iconColor}" d="M26.8,11c-1.5-0.2-3,0.2-4.3,1c-0.9,0.6-1.6,1.3-2.1,2.2c-0.5-0.9-1.2-1.7-2.1-2.2
        c-1.3-0.8-2.8-1.2-4.3-1c-1.4,0.2-2.7,0.9-3.6,2c-1,1.2-1.5,2.9-1.5,4.9c0,6.6,10.7,13.7,11.2,14c0.1,0.1,0.2,0.1,0.4,0.1
        c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4-0.1c0.5-0.3,11.1-7.4,11.1-14c0-2-0.5-3.7-1.5-4.9C29.6,11.9,28.3,11.2,26.8,11L26.8,11z
        M25.5,26.5c-2.1,2-4.2,3.5-5,4c-0.8-0.6-2.9-2.1-5-4c-2.4-2.2-5.2-5.6-5.2-8.6c0-1.7,0.4-3.1,1.2-4.1c0.7-0.8,1.7-1.4,2.8-1.5
        c2.2-0.3,4.8,1,5.5,3.9c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0,0.6-0.2,0.6-0.5c0.7-2.9,3.4-4.2,5.5-3.9
        c1.1,0.1,2.1,0.6,2.8,1.5c0.8,1,1.2,2.4,1.2,4.1C30.7,20.9,27.9,24.2,25.5,26.5L25.5,26.5z"/>
    `;

    const repair = `
        <path fill="${iconColor}" d="M21,35c7.7,0,14-6.3,14-14S28.7,7,21,7S7,13.3,7,21S13.3,35,21,35z M21,9c6.6,0,12,5.4,12,12s-5.4,12-12,12
            S9,27.6,9,21S14.4,9,21,9z"/>
        <path fill="${iconColor}" d="M13.7,25.5l2.5-2.5c0.3-0.3,0.4-0.7,0.2-1c-0.8-2.2-0.2-4.6,1.4-6.2c1.5-1.5,3.7-2.1,5.8-1.5l-2.3,2.3
            c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0l2.3-2.3c0.5,2,0,4.3-1.5,5.8c-1.6,1.6-4.1,2.2-6.2,1.4c-0.4-0.1-0.8,0-1,0.2
            l-2.5,2.5c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l2.1-2.1c2.7,0.7,5.7,0,7.7-2.1
            c2.5-2.5,3-6.4,1.4-9.4c-0.1-0.3-0.4-0.5-0.7-0.5c-0.3,0-0.6,0.1-0.8,0.3l-3.3,3.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4
            l3.3-3.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.6-0.5-0.7c-3.1-1.7-7-1.1-9.4,1.4c-2,2-2.8,5-2.1,7.7l-2.1,2.1c-0.4,0.4-0.4,1,0,1.4
            C12.7,25.9,13.3,25.9,13.7,25.5L13.7,25.5z"/>
    `;

    const parking = `
        <path fill-rule="evenodd" clip-rule="evenodd" fill="${iconColor}" d="M19.4,24.5h2.8c3.1,0,5.6-2.5,5.6-5.6c0-3.1-2.5-5.6-5.6-5.6H18
            c-0.8,0-1.4,0.6-1.4,1.4v12.5c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4V24.5z M19.4,16.1v5.6h2.8c1.6,0,2.8-1.2,2.8-2.8
            c0-1.5-1.3-2.8-2.8-2.8H19.4z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" fill="${iconColor}" d="M7,21c0-7.7,6.3-14,14-14s14,6.3,14,14s-6.3,14-14,14S7,28.7,7,21
            L7,21z M21,9.8c-6.2,0-11.2,5-11.2,11.2s5,11.2,11.2,11.2s11.2-5,11.2-11.2S27.2,9.8,21,9.8z"/>
    `;

    const calendar = `
    <path fill="#FFFFFF" d="M31.82,34.47H9.18c-1.44-0.01-2.61-1.17-2.61-2.61v-20.7c0.01-1.44,1.17-2.61,2.61-2.61h2.41v3.21
	c0,0.87,0.4,1.69,1.09,2.23c1.24,1,3.01,1,4.25,0c0.68-0.54,1.08-1.36,1.09-2.23V8.55h4.87v3.21c0,0.87,0.4,1.69,1.09,2.23
	c1.24,1,3.01,1,4.25,0c0.68-0.54,1.08-1.36,1.09-2.23V8.55h2.52c1.44,0.01,2.6,1.17,2.61,2.61v20.7
	C34.43,33.3,33.26,34.47,31.82,34.47z M25.99,19.11h3.74c0.26,0,0.47,0.21,0.48,0.46v3.11c-0.01,0.26-0.22,0.46-0.48,0.46h-3.74
	c-0.25-0.01-0.46-0.21-0.46-0.46v-3.1c0.01-0.25,0.21-0.46,0.46-0.46V19.11z M18.67,19.11h3.74c0.25,0.01,0.46,0.21,0.46,0.46v3.11
	c-0.01,0.25-0.21,0.46-0.46,0.46h-3.74c-0.25-0.01-0.46-0.21-0.46-0.46v-3.1c0.01-0.25,0.21-0.46,0.46-0.46V19.11z M11.27,19.11
	h3.74c0.25,0.01,0.46,0.21,0.46,0.46v3.11c-0.01,0.25-0.21,0.46-0.46,0.46h-3.74c-0.26,0-0.47-0.21-0.48-0.46v-3.1
	c0.01-0.26,0.22-0.46,0.48-0.46V19.11z M25.99,25.73h3.74c0.26,0,0.47,0.21,0.48,0.46v3.11c0,0.26-0.22,0.48-0.48,0.48h-3.74
	c-0.26-0.01-0.46-0.22-0.46-0.48v-3.1c0.01-0.25,0.21-0.46,0.46-0.46V25.73z M18.67,25.73h3.74c0.25,0.01,0.46,0.21,0.46,0.46v3.11
	c0,0.26-0.21,0.47-0.46,0.48h-3.74c-0.26-0.01-0.46-0.22-0.46-0.48v-3.1c0.01-0.25,0.21-0.46,0.46-0.46V25.73z M11.27,25.73h3.74
	c0.25,0.01,0.46,0.21,0.46,0.46v3.11c0,0.26-0.21,0.47-0.46,0.48h-3.74c-0.26,0-0.48-0.21-0.48-0.48v-3.1
	c0.01-0.26,0.22-0.46,0.48-0.46V25.73z M24.83,6.96c0-0.57,0.56-1.03,1.25-1.03s1.26,0.46,1.26,1.03v4.81
	c0,0.57-0.56,1.03-1.26,1.03c-0.7,0-1.26-0.46-1.26-1.03L24.83,6.96L24.83,6.96z M13.54,6.96c0-0.57,0.57-1.03,1.26-1.03
	s1.26,0.46,1.26,1.03v4.81c0,0.57-0.56,1.03-1.26,1.03s-1.26-0.46-1.26-1.03V6.96z M7.4,16.11v15.47c0,1.13,0.92,2.05,2.05,2.05
	h22.07c1.13,0,2.05-0.92,2.06-2.05V16.11H7.4z"/>
    `;

    const camera = `
        <svg width="50" height="58" viewBox="0 0 50 58" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_423_714" fill="white">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.2295 48.2411C43.4685 44.569 50 35.5473 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 35.5473 6.53156 44.569 15.7706 48.2411L25 57.4706L34.2295 48.2411Z"/>
            </mask>
            <path fill="${color}" fill-rule="evenodd" clip-rule="evenodd" d="M34.2295 48.2411C43.4685 44.569 50 35.5473 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 35.5473 6.53156 44.569 15.7706 48.2411L25 57.4706L34.2295 48.2411Z"/>
            <path d="M34.2295 48.2411L33.8601 47.3118L33.6683 47.3881L33.5224 47.534L34.2295 48.2411ZM15.7706 48.2411L16.4777 47.534L16.3317 47.3881L16.1399 47.3119L15.7706 48.2411ZM25 57.4706L24.2929 58.1777L25 58.8848L25.7071 58.1777L25 57.4706ZM49 25C49 35.1238 42.7313 43.7859 33.8601 47.3118L34.5988 49.1704C44.2056 45.3521 51 35.9707 51 25H49ZM25 1C38.2548 1 49 11.7452 49 25H51C51 10.6406 39.3594 -1 25 -1V1ZM1 25C1 11.7452 11.7452 1 25 1V-1C10.6406 -1 -1 10.6406 -1 25H1ZM16.1399 47.3119C7.2687 43.7859 1 35.1238 1 25H-1C-1 35.9708 5.79443 45.3522 15.4012 49.1704L16.1399 47.3119ZM25.7071 56.7635L16.4777 47.534L15.0635 48.9483L24.2929 58.1777L25.7071 56.7635ZM33.5224 47.534L24.2929 56.7635L25.7071 58.1777L34.9366 48.9482L33.5224 47.534Z" fill="white" mask="url(#path-1-inside-1_423_714)"/>
            <path fill="${iconColor}" fill-rule="evenodd" clip-rule="evenodd" d="M41.1576 25.6509L20.4925 13.8238C19.8817 13.4194 19.169 13.7226 18.7618 14.2281L15.8096 19.3834L26.7021 25.752L41.6667 26.2575L41.1576 25.651L41.1576 25.6509ZM9.09091 26.5608H9.19283C10.2108 26.5608 11.2288 27.1673 11.5343 28.1781C11.6362 28.4814 11.7379 28.7846 11.7379 29.0879V29.1891C12.5522 29.6947 13.4685 30.1999 14.3846 30.7055C15.4025 31.4132 16.5224 31.8175 17.2349 31.6154C18.4565 31.312 18.9656 29.8969 19.678 28.5829L18.8636 27.7743C18.5581 27.2687 19.3727 26.157 19.9835 25.2471C18.4564 24.4384 14.9954 22.7199 14.6899 21.9113C14.588 21.3048 14.8935 21.0014 15.199 20.4961L26.4989 26.8647L37.2896 27.269C35.7626 29.8972 34.3374 33.5366 32.8106 32.6265L23.6488 27.37C22.1217 29.9982 22.5289 29.796 21.0021 29.2907C19.9841 31.0092 19.475 32.6265 17.6427 33.0309C17.6427 33.0309 16.3192 33.5364 14.9959 32.7275L11.2293 30.5037C10.8222 31.2114 10.0078 31.6157 9.1934 31.6157H9.09149V26.5614L9.09091 26.5608ZM40.5468 28.9867L38.3073 27.7737L35.8642 32.1203L38.002 33.3333L40.547 28.9867H40.5468Z" />
        </svg>    
    `;

    const icons = { snow, warning, heart, repair, parking, camera, picture, calendar };
    const iconSvg = icons[icon];

    return withoutMarker
        ? iconSvg
        : `
        <svg width="41" height="51" viewBox="0 0 41 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_333_1482" fill="white">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C2.68629 0 0 2.68629 0 6V36C0 39.3137 2.68628 42 5.99999 42H12.1317L20.1818 50.2219L28.2319 42H35.1224C38.4361 42 41.1224 39.3137 41.1224 36V6C41.1224 2.68629 38.4361 0 35.1224 0H6Z"/>
            </mask>
            <path fill="${color}" fill-rule="evenodd" clip-rule="evenodd" d="M6 0C2.68629 0 0 2.68629 0 6V36C0 39.3137 2.68628 42 5.99999 42H12.1317L20.1818 50.2219L28.2319 42H35.1224C38.4361 42 41.1224 39.3137 41.1224 36V6C41.1224 2.68629 38.4361 0 35.1224 0H6Z"/>
            <path d="M12.1317 42L12.8462 41.3004L12.5521 41H12.1317V42ZM20.1818 50.2219L19.4673 50.9215L20.1818 51.6513L20.8963 50.9215L20.1818 50.2219ZM28.2319 42V41H27.8115L27.5174 41.3004L28.2319 42ZM1 6C1 3.23858 3.23858 1 6 1V-1C2.13401 -1 -1 2.13401 -1 6H1ZM1 36V6H-1V36H1ZM5.99999 41C3.23857 41 1 38.7614 1 36H-1C-1 39.866 2.134 43 5.99999 43V41ZM12.1317 41H5.99999V43H12.1317V41ZM20.8963 49.5223L12.8462 41.3004L11.4171 42.6996L19.4673 50.9215L20.8963 49.5223ZM27.5174 41.3004L19.4673 49.5223L20.8963 50.9215L28.9465 42.6996L27.5174 41.3004ZM35.1224 41H28.2319V43H35.1224V41ZM40.1224 36C40.1224 38.7614 37.8838 41 35.1224 41V43C38.9884 43 42.1224 39.866 42.1224 36H40.1224ZM40.1224 6V36H42.1224V6H40.1224ZM35.1224 1C37.8838 1 40.1224 3.23857 40.1224 6H42.1224C42.1224 2.13401 38.9884 -1 35.1224 -1V1ZM6 1H35.1224V-1H6V1Z" fill="white" mask="url(#path-1-inside-1_333_1482)"/>
            ${iconSvg}
        </svg>
    `;
};

export default getContributionSvg;
